@import '../../../styles/Variables';

.actionBarLabel {
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
}

.marginBetweenFiltersAndSort {
  margin-left: 20px;
}

.marginToSearchButton {
  margin-left: 10px;
}

.listFilter {
  min-width: unset;
  padding-right: 10px;
  :global {
    .combobox-content {
      .field-wrapper > label {
        .input-field {
          min-width: unset !important;
        }
        .input-field.is-dropdown input {
          font-size: 14px !important;
          height: 38px !important;
        }
      }
      .input-field.is-dropdown .multiple-choice-count {
        top: 20px !important;
      }
      .options-wrapper .options li.multiple {
        padding-top: 8px !important;
      }
      .options-wrapper .options .ellipsis {
        font-size: 14px !important;
      }
    }
  }
}

.actionBar {
  button.exportButton {
    margin: 0 16px;
    background-color: $geodocs--white;

    &:active,
    &:focus {
      background-color: $geodocs--white;

      i {
        color: $geodocs--black;
      }
    }

    i {
      color: $geodocs--black;
    }
  }
}
