@import '../../../../styles/Variables';

.transmittalTable {
  height: 100%;
  overflow: auto;
  table {
    table-layout: fixed;
    width: 100%;
    & thead {
      position: sticky;
      top: 0;
      z-index: 1;
      :global {
        tr.table-row.is-header {
          > td.table-cell {
            white-space: nowrap;

            .table-sort-icon {
              margin: 5px 0;
            }
          }
        }
      }

      .tableListFilter {
        min-width: unset;
        :global {
          .combobox-content {
            .field-wrapper > label {
              .input-field {
                min-width: unset !important;
              }
            }
          }
        }
      }
    }
  }
}

.tableLoading {
  overflow-y: hidden;

  td {
    cursor: default !important;
  }
}

.tableHeadColumn {
  width: fit-content;
}

.paginationWrapper {
  position: absolute;
  bottom: 0;
  height: 115px;
  width: 100%;
  background-color: $geodocs--white;
}

.paginationContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 30px;
  padding-top: 20px;
  .paginationFlexGrow {
    position: absolute;
    right: 48px;
    padding-top: 0.9375rem;
  }
}
